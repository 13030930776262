<div class="card">
    <div class="card-header" style="background-image: url({{country.flags.png}})"></div>
    <div class="card-body">
        <div class="card-title">
            <span>{{country.name.common}}</span>
        </div>
        <div class="card-description">
            <div>
                <span class="label">Population: </span>
                <span class="value">{{country.population}}</span>
            </div>

            <div>
                <span class="label">Region: </span>
                <span class="value">{{country.region}}</span>
            </div>

            <div>
                <span class="label">Capital: </span>
                <span class="value">{{country.capital}}</span>
            </div>
        </div>
    </div>
</div>
