<div class="container">
    <div class="content">
        <div class="filter-box" [formGroup]='countrySearchFormGroup'>
            <div class="search-by-term">
                <img src="assets/images/search.png" alt="search">
                <input formControlName='name' class="search-term-input" type="text" placeholder="Search for a country..." />
            </div>
            <div class="search-by-region">
                <select formControlName='region' class="search-by-region-select">
                    <option value='false'>Filter by region</option>
                    <option value="Africa">Africa</option>
                    <option value="Americas">Americas</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="Oceania">Oceania</option>
                </select>
            </div>
        </div>
        <div
            class="countries-container"
            *ngIf='(countries$ | async); let countries'
        >
            <ng-container>
                    <app-country-card
                    class="country-card"
                    *ngFor='let country of countries'
                    routerLink='/country/{{country.cca2}}'
                    [country]="country"
                    ></app-country-card>
            </ng-container>
        </div>
    </div>
</div>
