<header class="header">
    <div class="header-container">
        <div class="app-title">
            <h1>Countries viewer</h1>
        </div>
        <div (click)='switchTheme()' class="theme-switcher">
            <img *ngIf='themeSwitcher.theme=="light-theme"' src="assets/images/sun.png" alt="sun">
            <img *ngIf='themeSwitcher.theme=="dark-theme"' src="assets/images/moon.png" alt="moon">
             <span>Swich theme</span>
        </div>
    </div>
</header>