<div class="container" *ngIf="(country$ | async); let country">
    <div class="content" *ngIf='country'>
        <div class="back-box">
            <a href="#" routerLink='/'>Back</a>
        </div>
        <div class="country-container">
            <div class="country-flag">
                <div class="image-container">
                    <img [src]="country.flags.png" alt="flag">
                </div>
            </div>
            <div class="country-info">
                <div class="country-info-box">
                    <div class="title">
                        <h1>{{country.name.official}}</h1>
                    </div>
                    <div class="description">
                        <div>
                            <div class='label-value-container'>
                                <span  class='label'>Native Name: </span>
                                <span  class='value'>{{country.name.common}}</span>

                            </div>
                            <div class='label-value-container'>
                                <span  class='label'>Population: </span>
                                <span  class='value'>{{country.population}}</span>
                            </div>
                            <div class='label-value-container'>
                                <span  class='label'>Region: </span>
                                <span  class='value'>{{country.region}}</span>
                            </div>
                            <div class='label-value-container'>
                                <span  class='label'>Sub Region: </span>
                                <span  class='value'>{{country.subregion}}</span>
                            </div>
                            <div class='label-value-container'>
                                <span  class='label'>Capital: </span>
                                <span  class='value'>{{country.capital}}</span>
                            </div>
                        </div>
                        <div>
                            <div class='label-value-container'>
                                <span class='label'>Area: </span>
                                <span class='value'>{{country.area}}</span>
                            </div>
                            <div class='label-value-container'>
                                <span  class='label'>Languages: </span>
                                <span  class='value'>
                                  <ng-container *ngIf='country.languages.spa'>{{country.languages.spa}}</ng-container>
                                  <ng-container *ngIf='country.languages.eng'>{{country.languages.eng}}</ng-container>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="borders">
                        <span class="border-text">Border Countries: </span>
                        <div class="btn-borders">
                            <a *ngFor="let border of country.borders">
                              {{border}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
